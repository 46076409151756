// @flow
import React from 'react'
import classnames from 'classnames'

import styles from './Backdrop.module.scss'

type Props = {|
  onExit: () => void,
  show: boolean,
|}

export default function Backdrop({ onExit, show }: Props) {
  return (
    <div
      className={classnames(styles.wrapper, { [styles.show]: show })}
      onClick={onExit}
    />
  )
}
