// @flow
import React, { type Node } from 'react'
import classnames from 'classnames'
import { MdClose } from 'react-icons/md'

import styles from './Modal.module.scss'

type Props = {|
  children: Node,
    onExit: () => void,
      show: boolean,
|}

export default function Modal({ children, onExit, show }: Props) {
  return (
    <div
      className={classnames(styles.wrapper, {
        [styles.show]: show,
      })}
    >
      <div className={styles.closeWrapper}>
        <MdClose onClick={onExit} className={styles.close} />
      </div>
      <div className={styles.body}>{children}</div>
    </div>
  )
}
