import React from 'react'
import flatten from 'flat'
import { IntlProvider } from 'react-intl'

import { determineLocale } from './utils'
import { DEFAULT_LOCALE } from './consts'
import dictionaries from './dictionaries'

export default function withIntl(WrappedComponent) {
  return function WithIntl(props) {
    const locale = determineLocale(props.location)

    return (
      <IntlProvider
        locale={locale}
        defaultLocale={DEFAULT_LOCALE}
        messages={flatten(dictionaries[locale])}
      >
        <WrappedComponent {...props} />
      </IntlProvider>
    )
  }
}
