// @flow
import React from 'react'
import { useIntl } from 'react-intl'

import { LOCALE_FLAGS } from 'src/intl/consts'

import styles from './NavbarLocale.module.scss'

type Props = {|
  onClick: () => void,
|}

export default function NavbarLocale({ onClick }: Props) {
  const { locale } = useIntl()

  return (
    <img
      onClick={onClick}
      src={LOCALE_FLAGS[locale].icon}
      alt={LOCALE_FLAGS[locale].name}
      className={styles.icon}
    />
  )
}
