// @flow
import React, { type Node } from 'react'

import styles from './FooterColumn.module.scss'

type Props = {|
  title: string,
  children: Node,
|}

const FooterColumn = ({ title, children }: Props) => {
  return (
    <ul className={styles.column}>
      <li className={styles.title}>{title}</li>
      {children}
    </ul>
  )
}

export default FooterColumn
