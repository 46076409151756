// @flow
import urls from '../urls'
import { getRoutePath } from '..'

export default function getLocalesWithAlternatePage(pageUrlKey: ?string) {
  return Object.keys(urls).reduce((localesWithAlternatePage, locale) => {
    const regionUrl = getRoutePath(locale, pageUrlKey)
    if (regionUrl || regionUrl === '') {
      localesWithAlternatePage.push(locale)
    }
    return localesWithAlternatePage
  }, [])
}
