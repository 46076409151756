import { useIntl } from 'react-intl'

export default function useTranslateObject(object) {
  const intl = useIntl()
  let result = {}

  for (const item in object) {
    result[item] = intl.formatMessage({ id: object[item] })
  }

  return result
}
