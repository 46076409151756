//@flow

type Event = {
  [key: string]: string,
}
export default function pushEventToDataLayer(event: Event) {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(event)
  }
}
