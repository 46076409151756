// @flow

import React from 'react'
import classNames from 'classnames'

import styles from './Button.module.scss'

type Props = {|
  text: string,
  onClick: () => void,
  color?: string,
  className?: string,
|}

const Button = ({ text, color, className, onClick }: Props) => {
  return (
    <button
      onClick={onClick}
      className={classNames(styles.button, styles[color], className)}
    >
      {text}
    </button>
  )
}

export default Button
