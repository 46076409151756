//@flow
import React from 'react'
import { useIntl } from 'react-intl'
import Intercom from 'react-intercom'

import { LOCALE_FLAGS } from 'src/intl'
import ChatWidgetWhatsApp from './ChatWidgetWhatsApp'

const intercomId = 'fwcflxs6'

export default function ChatWidget() {
  const { locale } = useIntl()

  if (locale && LOCALE_FLAGS[locale].withWhatsAppWidget) {
    return (
      <ChatWidgetWhatsApp
        widgetReference={LOCALE_FLAGS[locale].withWhatsAppWidget}
      />
    )
  }

  if (locale && LOCALE_FLAGS[locale].withIntercom) {
    return <Intercom appID={intercomId} />
  }

  return null
}
