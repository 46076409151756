import { FLAG_BR, FLAG_MX, FLAG_UK, FLAG_US, FLAG_AT } from 'src/common/images'

export const LOCALES = {
  en_AU: 'en-AU',
  en_GB: 'en-GB',
  en_IN: 'en-IN',
  en_US: 'en-US',
  es_CO: 'es-CO',
  es_MX: 'es-MX',
  fr_FR: 'fr-FR',
  pt_BR: 'pt-BR',
  vi_VN: 'vi-VN',
  de_AT: 'de-AT',
}

export const LOCALE_FLAGS = {
  [LOCALES.en_GB]: {
    icon: FLAG_UK,
    locale: LOCALES.en_GB,
    name: 'UK',
    withIntercom: true,
  },
  [LOCALES.pt_BR]: {
    icon: FLAG_BR,
    locale: LOCALES.pt_BR,
    name: 'Brasil',
    withWhatsAppWidget: '47900',
  },
  [LOCALES.en_US]: {
    icon: FLAG_US,
    locale: LOCALES.en_US,
    name: 'USA',
    withIntercom: true,
  },
  [LOCALES.es_MX]: {
    icon: FLAG_MX,
    locale: LOCALES.es_MX,
    name: 'México',
    withWhatsAppWidget: '47902',
  },
  [LOCALES.de_AT]: {
    icon: FLAG_AT,
    locale: LOCALES.de_AT,
    name: 'Österreich',
  },
}

export const COUNTRY_TO_LOCALE = {
  GB: LOCALES.en_GB,
  US: LOCALES.en_US,
  CO: LOCALES.es_MX,
  MX: LOCALES.es_MX,
  BR: LOCALES.pt_BR,
  AT: LOCALES.de_AT,
}

export const DEFAULT_LOCALE = LOCALES.en_GB

export const LOCALES_ARRAY = Object.values(LOCALES)
