// @flow
import React from 'react'
import { useIntl } from 'react-intl'

import { linkToIndexPage, getRoutePath } from 'src/links'
import intlLinkFromId from 'src/links/utils/intlLinkFromId'
import { useTranslateString } from 'src/intl'

import Modal from 'src/common/components/Modal'
import Backdrop from 'src/common/components/Backdrop'
import { LOCALE_FLAGS } from 'src/intl/consts'

import LocaleButton from './components/LocaleButton'
import styles from './LocaleModal.module.scss'

type Props = {|
  onExit: () => void,
  show: boolean,
  pagePath: ?string,
|}

export default function LocaleModal({ onExit, show, pagePath }: Props) {
  const { locale } = useIntl()
  const chooseLocale = useTranslateString('common.navbar.chooseLocale')

  return (
    <>
      <Backdrop onExit={onExit} show={show} />
      <Modal onExit={onExit} show={show}>
        <h3 className={styles.heading}>{chooseLocale}</h3>
        <div className={styles.body}>
          {Object.values(LOCALE_FLAGS).map(flag => {
            const routePathDoesExist = getRoutePath(flag.locale, pagePath)
            const linkToIntlPath = intlLinkFromId(pagePath, flag.locale)
            const linkToIntlIndex = linkToIndexPage(flag.locale)

            const link = routePathDoesExist ? linkToIntlPath : linkToIntlIndex

            return (
              <LocaleButton
                key={flag.locale}
                icon={flag.icon}
                name={flag.name}
                link={link}
                active={flag.locale === locale}
              />
            )
          })}
        </div>
      </Modal>
    </>
  )
}
