import React from 'react'

import facebookIcon from 'src/common/images/sharing/facebook.svg'
import linkedInIcon from 'src/common/images/sharing/linkedin.svg'
import twitterIcon from 'src/common/images/sharing/twitter.svg'

import styles from './SharingSection.module.scss'

type Props = {
  shareText: string,
  shareLink: string,
}

const SharingSection = ({ shareText, shareLink }: Props) => (
  <div className={styles.shareContainer}>
    <a
      href={`https://twitter.com/intent/tweet?text=${shareText} @senecalearn&url=${shareLink}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={twitterIcon} />
    </a>
    <a
      href={`https://www.facebook.com/sharer/sharer.php?u=${shareLink}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={facebookIcon} />
    </a>
    <a
      href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareLink}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={linkedInIcon} />
    </a>
  </div>
)

export default SharingSection
