// @flow
import { useState, useEffect } from 'react'
import _ from 'lodash'

const OFFSET_TOP = 20

export default function useScrolledDown() {
  const [scrolledDown, setScrolledDown] = useState(false)

  useEffect(() => {
    const updateScrolledDown = _.throttle(
      () => {
        const { scrollY } = window
        if (scrollY < OFFSET_TOP) {
          setScrolledDown(false)
        } else {
          setScrolledDown(true)
        }
      },
      500,
      { leading: true }
    )

    window.addEventListener('scroll', updateScrolledDown)
    return () => window.removeEventListener('scroll', updateScrolledDown)
  }, [])

  return scrolledDown
}
