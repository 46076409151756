// @flow
import React from 'react'
import { Link } from 'gatsby'
import { useIntl } from 'react-intl'

import { intlLinkFromId } from 'src/links/utils'

import styles from './FooterLink.module.scss'

type Props = {|
  text: string,
  openInNewTab?: boolean,
  external?: boolean,
  linkInternal?: string,
  linkExternal?: string,
|}

export default function FooterLink({
  text,
  openInNewTab,
  external,
  linkInternal,
  linkExternal,
}: Props) {
  const { locale } = useIntl()

  return (
    <li className={styles.link}>
      {external ? (
        <a
          href={linkExternal}
          target={openInNewTab ? '_blank' : null}
          rel="noopener noreferrer"
        >
          {text}
        </a>
      ) : (
        <Link
          to={intlLinkFromId(linkInternal, locale)}
          target={openInNewTab ? '_blank' : null}
          rel="noopener noreferrer"
        >
          {text}
        </Link>
      )}
    </li>
  )
}
