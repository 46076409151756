// @flow

export const LOCAL_STORAGE_LOCALE_KEY = 'seneca.locale'
export const LOCAL_STORAGE_LOCALE_TOAST_DISMISSED_KEY =
  'seneca.localeToastDismissed'
export const LOCAL_STORAGE_COOKIE_TOAST_DISMISSED_KEY =
  'seneca.cookieToastDismissed'

const getItemFromLocalStorage = key => () => {
  if (typeof window !== 'undefined' && window.localStorage) {
    return window.localStorage.getItem(key)
  } else {
    console.error({
      message: `Could not get ${key} from localStorage`,
    })
  }
}

const setItemInLocalStorage = (key: string) => (value: boolean) => {
  if (typeof window !== 'undefined' && window.localStorage) {
    window.localStorage.setItem(key, value)
  } else {
    console.error({
      message: `Could not set ${key} in localStorage`,
    })
  }
}

export const getLocaleToastDismissedFromLocalStorage = getItemFromLocalStorage(
  LOCAL_STORAGE_LOCALE_TOAST_DISMISSED_KEY
)

export const setLocaleToastDismissedInLocalStorage = setItemInLocalStorage(
  LOCAL_STORAGE_LOCALE_TOAST_DISMISSED_KEY
)

export const getCookieToastDismissedFromLocalStorage = getItemFromLocalStorage(
  LOCAL_STORAGE_COOKIE_TOAST_DISMISSED_KEY
)

export const setCookieToastDismissedInLocalStorage = setItemInLocalStorage(
  LOCAL_STORAGE_COOKIE_TOAST_DISMISSED_KEY
)

export const getLocaleFromLocalStorage = getItemFromLocalStorage(
  LOCAL_STORAGE_LOCALE_KEY
)

export const setLocaleInLocalStorage = setItemInLocalStorage(
  LOCAL_STORAGE_LOCALE_KEY
)
