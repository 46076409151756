// @flow

import React from 'react'
import { useIntl } from 'react-intl'

import { intlLinkFromId } from 'src/links/utils'

import { Link } from 'gatsby'
import classNames from 'classnames'

import styles from './Button.module.scss'

export type ButtonProps = {|
  text: string,
  external?: boolean,
  openInNewTab?: boolean,
  linkExternal?: string,
  linkInternal?: string,
|}

type Props = {|
  color?: string,
  className?: string,
  ...ButtonProps,
|}

export default function ButtonLink({
  linkExternal,
  linkInternal,
  external,
  text,
  color,
  className,
  openInNewTab,
}: Props) {
  const { locale } = useIntl()
  const link = external ? linkExternal : intlLinkFromId(linkInternal, locale)

  if (external) {
    return (
      <a
        href={link}
        target={openInNewTab ? '_blank' : null}
        rel="noopener noreferrer"
        className={classNames(styles.button, styles[color], className)}
      >
        {text}
      </a>
    )
  } else {
    return (
      <Link
        to={link}
        target={openInNewTab ? '_blank' : null}
        className={classNames(styles.button, styles[color], className)}
      >
        {text}
      </Link>
    )
  }
}
