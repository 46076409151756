// @flow
import React, { useEffect } from 'react'

import { MdClose } from 'react-icons/md'

import { linkToIndexPage } from 'src/links'

import { LOCALE_FLAGS } from 'src/intl'

import LocaleButton from 'src/components/LocaleModal/components/LocaleButton'
import {
  logAnalyticsShowLocaleToastWarn,
  logAnalyticsDismissWarning,
  logAnalyticsSwitchToSuggestedRegion,
} from '../analytics'
import { TOAST_KEYS, type ToastKeyType } from '../consts'
import styles from '../Toast.module.scss'

type Props = {|
  setToastState: ToastKeyType => void,
  geoLocale: string,
  locale: string,
  defaultLocale: string,
  dictionaries: any,
|}

export default function LocaleToast({
  setToastState,
  geoLocale,
  locale,
  defaultLocale,
  dictionaries,
}: Props) {
  useEffect(() => {
    logAnalyticsShowLocaleToastWarn()
  }, [])
  // Using the dictionary directly, as ReactIntl wrapper contains a different locale
  const dictionary = dictionaries[geoLocale] || dictionaries[defaultLocale]
  const {
    heading,
    buttonText,
    textStart,
    textMiddle,
    textEnd,
  } = dictionary.localeToast

  const handleClose = () => {
    setToastState(TOAST_KEYS.locale)
    logAnalyticsDismissWarning(locale, geoLocale)
  }

  return (
    <div className={styles.wrapper} data-gtm="localeToast">
      <MdClose onClick={handleClose} className={styles.close} />
      <h3 className={styles.heading}>{heading}</h3>
      <div className={styles.description}>
        {textStart}
        {LOCALE_FLAGS[locale].name}
        {textMiddle}
        {LOCALE_FLAGS[geoLocale].name}
        {textEnd}
      </div>
      <LocaleButton
        onClick={() => {
          logAnalyticsSwitchToSuggestedRegion(locale, geoLocale)
        }}
        {...LOCALE_FLAGS[geoLocale]}
        link={linkToIndexPage(geoLocale)}
      />
      <div className={styles.buttonLink} onClick={handleClose}>
        {buttonText}
      </div>
    </div>
  )
}
