// @flow
import React from 'react'

import PageRow from 'src/common/components/PageRow'

import FooterColumn from './components/FooterColumn'
import FooterLink from './components/FooterLink'
import styles from './Footer.module.scss'

type Link = {|
  text: string,
  openInNewTab?: boolean,
  external?: boolean,
  linkExternal?: string,
  linkInternal?: string,
|}

type Column = {|
  title: string,
  list: Array<Link>,
|}

type Props = {|
  content: Array<Column>,
|}

const Footer = ({ content }: Props) => {
  const contentExists = content && content.length
  return (
    <PageRow className={styles.wrapper}>
      <div className={styles.footer}>
        {contentExists &&
          content.map(({ title, list }, i) => (
            <FooterColumn key={`footer-column-${i}`} title={title}>
              {list.map(link => (
                <FooterLink key={link.text} {...link} />
              ))}
            </FooterColumn>
          ))}
      </div>
    </PageRow>
  )
}

export default Footer
