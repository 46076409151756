import { DEFAULT_LOCALE, LOCALES_ARRAY } from '../consts'

const localeFromLocation = location =>
  location && location.pathname.split('/').filter(x => x)[0]

const stringIsLocale = locale => {
  return LOCALES_ARRAY.includes(locale)
}

export const determineLocale = location => {
  const pathLocale = localeFromLocation(location)

  if (stringIsLocale(pathLocale)) {
    return pathLocale
  } else {
    return DEFAULT_LOCALE
  }
}
