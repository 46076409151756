// @flow
import React from 'react'

import styles from './BackgroundImage.module.scss'

export default function BackgroundImage() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.rectangle} />
      <div className={styles.ellipse} />
    </div>
  )
}

export function BackgroundImageShort() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.ellipse} />
    </div>
  )
}
