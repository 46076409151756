// @flow

import React from 'react'
import { Link } from 'gatsby'

import styles from './NavbarLink.module.scss'

type Props = {|
  link: string,
  openInNewTab?: boolean,
  text: string,
  external?: boolean,
|}

export default function NavbarLink({
  link,
  openInNewTab,
  text,
  external,
}: Props) {
  if (external)
    return (
      <a
        href={link}
        target={openInNewTab ? '_blank' : null}
        rel="noopener noreferrer"
        className={styles.wrapper}
      >
        {text}
      </a>
    )
  else
    return (
      <Link to={link} className={styles.wrapper}>
        {text}
      </Link>
    )
}
