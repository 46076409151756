// @flow
import React from 'react'
import classnames from 'classnames'
import { Link } from 'gatsby'

import styles from './LocaleButton.module.scss'

type Props = {|
  icon: string,
  name: string,
  active: boolean,
  link: string,
  onClick?: () => void,
|}

export default function LocaleButton({
  icon,
  name,
  active,
  link,
  onClick,
}: Props) {
  return (
    <Link
      to={link}
      className={classnames(styles.wrapper, {
        [styles.active]: active,
      })}
      onClick={onClick}
    >
      <img src={icon} className={styles.icon} alt={name} />
      <div className={styles.text}>{name}</div>
    </Link>
  )
}
