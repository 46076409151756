// @flow

import {
  linkToBlogPage,
  linkToCookiesPage,
  linkToDefinitionsPage,
  linkToPressKit,
  linkToTermsPage,
  linkToTeachersPage,
  linkToParentsPage,
  linkToIndexPage,
  linkToSyllabusPage,
  linkToPrivacyPage,
  linkToVocabPage,
} from 'src/links'

export default function intlLinkFromId(link: string, locale: string) {
  switch (link) {
    case 'blog':
      return linkToBlogPage(locale)
    case 'cookies':
      return linkToCookiesPage(locale)
    case 'definitions':
      return linkToDefinitionsPage(locale)
    case 'index':
      return linkToIndexPage(locale)
    case 'parents':
      return linkToParentsPage(locale)
    case 'press-kit':
      return linkToPressKit(locale)
    case 'privacy':
      return linkToPrivacyPage(locale)
    case 'teachers':
      return linkToTeachersPage(locale)
    case 'terms':
      return linkToTermsPage(locale)
    case 'syllabus':
      return linkToSyllabusPage(locale)
    case 'vocab':
      return linkToVocabPage(locale)
    default:
      return link
  }
}
