// @flow

import uuid from 'uuid'

import { COUNTRY_TO_LOCALE, DEFAULT_LOCALE } from 'src/intl/consts'

import {
  getLocaleFromLocalStorage,
  setLocaleInLocalStorage,
} from 'src/common/utils/localStorage'

export async function getAndSetLocaleIfNeeded() {
  const localStorageLocale = getLocaleFromLocalStorage()

  if (localStorageLocale) {
    return localStorageLocale
  } else {
    const locale = await fetchUserLocale()
    setLocaleInLocalStorage(locale)
    return locale
  }
}

export async function fetchUserLocale() {
  const response = await fetch(
    'https://geolocation.app.senecalearning.com/api/info',
    {
      method: 'GET',
      headers: {
        correlationId: uuid.v4(),
      },
    }
  )

  if (response.status >= 200 && response.status <= 299) {
    const { countryCode } = await response.json()
    return COUNTRY_TO_LOCALE[countryCode] || DEFAULT_LOCALE
  } else {
    console.log(response.status, response.statusText)
    return DEFAULT_LOCALE
  }
}
