// @flow
import React, { type Node, useState } from 'react'
import classnames from 'classnames'
import { FiMenu, FiX } from 'react-icons/fi'

import styles from './NavbarMobile.module.scss'

type Props = {|
  children: Node,
|}

export default function NavbarMobile({ children }: Props) {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen)
  }

  return (
    <div className={styles.wrapper}>
      <FiMenu className={styles.menu} onClick={handleSidebarToggle} />
      <div
        className={classnames(styles.sidebar, { [styles.open]: sidebarOpen })}
      >
        <div className={styles.closeWrapper}>
          <FiX onClick={handleSidebarToggle} />
        </div>
        {children}
      </div>
    </div>
  )
}
