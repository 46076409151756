// @flow

const urls_en_GB = require('./urls_en-gb')
const urls_en_US = require('./urls_en-us')
const urls_pt_BR = require('./urls_pt-br')
const urls_es_MX = require('./urls_es-mx')
const urls_de_AT = require('./urls_de-at')

const urls = {
  'en-GB': urls_en_GB.urls,
  'en-US': urls_en_US.urls,
  'es-MX': urls_es_MX.urls,
  'pt-BR': urls_pt_BR.urls,
  'de-AT': urls_de_AT.urls,
}

module.exports = urls
