// @flow
import React from 'react'
import { Link } from 'gatsby'
import { MdClose } from 'react-icons/md'

import { linkToCookiesPage } from 'src/links'

import Button from 'src/common/components/Button'
import { TOAST_KEYS, type ToastKeyType } from '../consts'
import styles from '../Toast.module.scss'

type Props = {|
  setToastState: ToastKeyType => void,
  locale: string,
  defaultLocale: string,
  dictionaries: any,
|}

export default function CookieToast({
  setToastState,
  locale,
  defaultLocale,
  dictionaries,
}: Props) {
  const handleClose = () => {
    setToastState(TOAST_KEYS.cookie)
  }

  // Using the dictionary directly, as ReactIntl wrapper contains a different locale
  const dictionary = dictionaries[locale] || dictionaries[defaultLocale]
  const { heading, text, manageText, acceptText } = dictionary.cookieToast

  return (
    <div className={styles.wrapper} onClick={handleClose}>
      <MdClose onClick={handleClose} className={styles.close} />
      <h3 className={styles.heading}>{heading} 🍪</h3>
      <div className={styles.description}>{text}</div>

      {locale === 'en-GB' || locale === 'en-US' ? (
        <Link className={styles.buttonLink} to={linkToCookiesPage(locale)}>
          {manageText}
        </Link>
      ) : null
      /* 
      Will show on all locales once cookie policy translated for BR & MX
      */
      }

      <Button color="blue" onClick={handleClose} text={acceptText} />
    </div>
  )
}
