// @flow
import * as React from 'react'
import styles from './Layout.module.scss'
import Toast from 'src/components/Toasts'

type Props = {|
  children: {|
    navbar: React.Node,
    background: React.Node,
    body: React.Node,
    footer?: React.Node,
  |},
|}

const Layout = ({ children: { navbar, background, body, footer } }: Props) => {
  return (
    <div className={styles.container}>
      {background}
      {body}
      {footer}
      {navbar}
      <Toast />
    </div>
  )
}

export default Layout
