// @flow
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

const widgetId = 'okewa'
const whatsAppWidgetSrc = 'https://cdn.widgetwhats.com/script.min.js'

type Props = {|
  widgetReference: string,
|}

export default function ChatWidgetWhatsApp({ widgetReference }: Props) {
  useEffect(() => {
    return function removeWidget() {
      const script = document.querySelectorAll(
        `script[data-id="${widgetReference}"]`
      )
      const widget = document.getElementById(widgetId)
      script && script[0] && script[0].remove()
      widget && widget.remove()
    }
  }, [widgetReference])

  return (
    <Helmet>
      <script async src={whatsAppWidgetSrc} data-id={widgetReference} />
    </Helmet>
  )
}
