// @flow
import React from 'react'
import SenecaLogo from 'src/common/components/Logos/components/SenecaLogo'
import styles from './NavbarTutoring.module.scss'

export default function NavbarTutoring() {
  return (
    <div className={styles.wrapper}>
      <SenecaLogo />
    </div>
  )
}
