// @flow
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'

import { DEFAULT_LOCALE } from 'src/intl/consts'
import dictionaries from 'src/intl/dictionaries'

import ChatWidget from 'src/components/ChatWidget'

import { getAndSetLocaleIfNeeded } from 'src/common/services/geolocationService'

import {
  getCookieToastDismissedFromLocalStorage,
  getLocaleToastDismissedFromLocalStorage,
  setCookieToastDismissedInLocalStorage,
  setLocaleToastDismissedInLocalStorage,
} from 'src/common/utils/localStorage'

import CookieToast from './CookieToast'
import LocaleToast from './LocaleToast'
import { TOAST_KEYS, type ToastKeyType } from './consts'

export default function Toast() {
  const [showCookieToast, setShowCookieToast] = useState(false)
  const [geoLocale, setGeoLocale] = useState(null)

  useEffect(() => {
    async function setStateIfNeeded() {
      setGeoLocale(await getAndSetLocaleIfNeeded())
      setShowCookieToast(!(await getCookieToastDismissedFromLocalStorage()))
    }
    setStateIfNeeded()
  }, [setGeoLocale, setShowCookieToast])

  const { locale } = useIntl()

  const showLocaleToast =
    !getLocaleToastDismissedFromLocalStorage() &&
    geoLocale &&
    locale !== geoLocale

  const setToastDissmissed = (type: ToastKeyType) => {
    if (type === TOAST_KEYS.locale) {
      setLocaleToastDismissedInLocalStorage(true)
      setGeoLocale(null)
    } else if (type === TOAST_KEYS.cookie) {
      setCookieToastDismissedInLocalStorage(true)
      setShowCookieToast(false)
    }
  }

  if (showLocaleToast) {
    return (
      <LocaleToast
        setToastState={setToastDissmissed}
        geoLocale={geoLocale}
        locale={locale}
        defaultLocale={DEFAULT_LOCALE}
        dictionaries={dictionaries}
      />
    )
  } else if (showCookieToast) {
    return (
      <CookieToast
        setToastState={setToastDissmissed}
        locale={locale}
        defaultLocale={DEFAULT_LOCALE}
        dictionaries={dictionaries}
      />
    )
  }
  return <ChatWidget />
}
