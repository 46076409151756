// @flow
import merge from 'deepmerge'

import { LOCALES } from '../consts'

import messages_en_GB from './en-gb'
import messages_es_MX from './es-mx'
import messages_fr_FR from './fr-fr'
import messages_pt_BR from './pt-br'
import messages_de_AT from './de-at'

export default {
  [LOCALES.en_GB]: messages_en_GB,
  [LOCALES.en_US]: messages_en_GB,
  [LOCALES.es_MX]: merge(messages_en_GB, messages_es_MX),
  [LOCALES.es_CO]: merge(messages_en_GB, messages_es_MX),
  [LOCALES.fr_FR]: merge(messages_en_GB, messages_fr_FR),
  [LOCALES.pt_BR]: merge(messages_en_GB, messages_pt_BR),
  [LOCALES.de_AT]: merge(messages_en_GB, messages_de_AT),
}
