// @flow
import * as React from 'react'
import classNames from 'classnames'

import styles from './PageRow.module.scss'

type Props = {
  firstRow?: boolean,
  className?: string,
  children: React.Node,
}

const PageRow = (props: Props) => {
  return (
    <div
      className={classNames(styles.container, props.className, {
        [styles.firstRow]: props.firstRow,
      })}
    >
      {props.children}
    </div>
  )
}

export default PageRow
