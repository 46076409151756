import urls from './urls'

export const getRoutePath = (locale, route) => {
  const url = urls[locale] && urls[locale][route]
  if (!url) return null
  return url === '_' ? '' : url
}

export const intlRoute = route => locale =>
  `/${locale}/${getRoutePath(locale, route)}`

const intlRouteWithSlug = route => (locale, slug) =>
  `/${locale}/${getRoutePath(locale, route)}/${slug}`

const staticSiteRoot = 'https://senecalearning.com'
const webAppRoot = 'https://app.senecalearning.com'

export const externalStaticSiteLink = route => locale =>
  `${staticSiteRoot}${intlRoute(route)(locale)}`

// Landing Pages

export const linkToIndexPage = intlRoute('index')

export const linkToParentsPage = intlRoute('parents')

export const linkToTeachersPage = intlRoute('teachers')

// Post Pages

export const linkToBlogPage = intlRoute('blog')

export const linkToDefinitionsPage = intlRoute('definitions')

export const linkToSyllabusPage = intlRoute('syllabus')

export const linkToVocabPage = intlRoute('vocab')

// Posts

export const linkToBlogPost = intlRouteWithSlug('blog')

export const linkToDefinitionsPost = intlRouteWithSlug('definitions')

export const linkToSyllabusPost = intlRouteWithSlug('syllabus')

export const linkToVocabPost = intlRouteWithSlug('vocab')

// Other Pages

export const linkToCookiesPage = intlRoute('cookies')

export const linkToPressKit = intlRoute('press-kit')

export const linkToPrivacyPage = intlRoute('privacy')

export const linkToTermsPage = intlRoute('terms')

// Web App Links

const webAppLink = path => () => `${webAppRoot}${path}`

export const linkToAppCoursesPage = webAppLink('/courses?Price=Free')

export const linkToAppTeacherPlatform = webAppLink('/teacher')

export const linkToAppParentPlatform = webAppLink('/what-is-premium')

export const linkToAppLogin = webAppLink('/login')

export const linkToAppSignup = webAppLink('/sign-up')

export const linkToAppJoinClass = webAppLink(
  '/dashboard/classes/overview/join-class'
)

export const teacherCPD = webAppLink('/courses?Price=Free&Level=Teacher+CPD')
