// @flow
import React from 'react'
import { Link } from 'gatsby'
import { useIntl } from 'react-intl'

import { SENECA_LOGO, SENECA_BY_GO_STUDENT_LOGO } from 'src/common/images'
import { linkToIndexPage } from 'src/links'
import { LOCALES } from 'src/intl/consts'

import styles from './SenecaLogo.module.scss'

const SenecaLogo = () => {
  const { locale } = useIntl()

  return (
    <Link to={linkToIndexPage(locale)} className={styles.container}>
      <img
        src={locale === LOCALES.en_GB ? SENECA_LOGO : SENECA_BY_GO_STUDENT_LOGO}
        alt="Seneca logo"
      />
    </Link>
  )
}

export default SenecaLogo
