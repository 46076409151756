// @flow
import * as React from 'react'
import classnames from 'classnames'

import styles from './Heading.module.scss'

type Props = {|
  text: React.Node,
  level?: '1' | '2' | '3' | '4',
  color?: 'white' | 'black',
|}

export default function Heading({ text, level = '1', color = 'white' }: Props) {
  const Component = headingComponent[level]
  return (
    <Component
      className={classnames({
        [styles.levelOne]: level === '1',
        [styles.levelTwo]: level === '2',
        [styles.colorWhite]: color === 'white',
        [styles.colorBlack]: color === 'black',
      })}
    >
      {text}
    </Component>
  )
}

const headingComponent = {
  '1': 'h1',
  '2': 'h2',
  '3': 'h3',
  '4': 'h4',
}
