//@flow

import pushEventToDataLayer from '../../analytics/pushEventToDataLayer'

const showLocaleToastEvent = 'publicSite/showLocaleWarningToast'
export function logAnalyticsShowLocaleToastWarn() {
  pushEventToDataLayer({ event: showLocaleToastEvent })
}

const dismissLocalToastEvent = 'publicSite/dismissLocaleWarningToast'
export function logAnalyticsDismissWarning(
  currentRegion: string,
  suggestedRegion: string
) {
  pushEventToDataLayer({
    event: dismissLocalToastEvent,
    eventAction: `dismiss ${suggestedRegion} being on ${currentRegion}`,
  })
}

const switchToSuggestedRegionEvent = 'publicSite/switchToSuggestedRegion'
export function logAnalyticsSwitchToSuggestedRegion(
  currentRegion: string,
  suggestedRegion: string
) {
  pushEventToDataLayer({
    event: switchToSuggestedRegionEvent,
    eventAction: `switch from ${currentRegion} to ${suggestedRegion}`,
  })
}
