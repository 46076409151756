// @flow
import React, { type Node } from 'react'

import styles from './NavbarDesktop.module.scss'

type Props = {|
  children: Node,
|}

export default function NavbarDesktop({ children }: Props) {
  return <div className={styles.wrapper}>{children}</div>
}
